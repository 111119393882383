import * as React from 'react';
import { Row } from 'react-bootstrap';
import '../styles/app.scss';

const SectionTitle = (props) => {
    return <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
        <span className='section-title'>{props.title}</span>
    </Row>;
}

export default SectionTitle;