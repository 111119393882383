import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './navbar.scss';

const scrollToRef = (theRef) => {
    theRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

const NavBar = (props) => {
    const isFooter = props.isFooter;
    return <div className={isFooter ? 'navbar footer' : 'navbar'}>
        <Container style={{ display: 'block' }}>

            <Row className='navbar-content'>
                <Col md={2}><h3 className='no-select' style={{ margin: '0px' }}>Sajad J</h3></Col>
                <Col md={10} className='navbar-nav-items-container'>
                    <ul className='no-select'>
                        <li><button onClick={() => scrollToRef(props.abtRef)}>About</button></li>
                        <li><button onClick={() => scrollToRef(props.serviceRef)}>Services</button></li>
                        <li><button onClick={() => scrollToRef(props.qualifRef)}>Qualifications</button></li>
                        <li><button onClick={() => scrollToRef(props.certRef)}>Certifications</button></li>
                        <li><button onClick={() => scrollToRef(props.contactRef)}>Contact</button></li>
                    </ul>
                </Col>

                <Col md={10} className='navbar-nav-items-icon'>
                    <FontAwesomeIcon icon={faBars} />
                </Col>



            </Row>


        </Container>
    </div>;
};

export default NavBar;