import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../styles/app.scss';
import './about.scss';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

const scrollToRef = (theRef) => {
    logEvent(analytics, 'button_click', { 'name': 'contact me' });
    theRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

const About = (props, ref) => {
    return <Container className='about-main-container' ref={ref} style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '100px' }}>
        <Row style={{ maxWidth: '768px' }}>
            <Col className='self-portrait-container-m' md={4} style={{ textAlign: 'center' }}>
                <img src='/self.webp' className='self-portrait' width="256px" alt='' />
            </Col>
            <Col md={8}>
                <span className='hi-text'>Hi, I'm Sajad</span>
                <p>Software Engineer</p>
                <p>I help bring your ideas into reality by following up-to-date tech stack, quality work and competitive experience all together</p>
                <Button onClick={() => scrollToRef(props.contactRef)}>Contact Me</Button>
            </Col>
            <Col className='self-portrait-container' md={4} style={{ textAlign: 'center' }}>
                <img src='/self.webp' className='self-portrait' width="256px" alt='' />
            </Col>
        </Row>
    </Container>;
};

export default React.forwardRef(About);