import * as React from 'react';
import { Card, Col } from 'react-bootstrap';

const ServiceCard = (props) => <Col md={3}>
    <Card onClick={() => props.onTapped(props.title)}>
        <Card.Body>
            <img src={`/services/${props.fileName}.png`} alt='' />
            <p>{props.title}</p>
        </Card.Body>
    </Card></Col>;

export default ServiceCard;