import React, { useRef } from "react";
import NavBar from "./navbar/Navbar";
import About from "./sections/about/about";
import Services from "./sections/services/services";
import "./styles/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Timeline from "./sections/timeline/timeline";
import Certifications from "./sections/certifications/certifications";
import Contact from "./sections/contact/contact";

function App() {
  const abtRef = useRef(null);
  const serviceRef = useRef(null);
  const qualifRef = useRef(null);
  const certRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="App">
      <NavBar
        abtRef={abtRef}
        serviceRef={serviceRef}
        qualifRef={qualifRef}
        certRef={certRef}
        contactRef={contactRef}
      />
      <About ref={abtRef} contactRef={contactRef} />
      <Services ref={serviceRef} />
      <Timeline ref={qualifRef} />
      <Certifications ref={certRef} />
      <Contact ref={contactRef} />

      <NavBar
        isFooter
        abtRef={abtRef}
        serviceRef={serviceRef}
        qualifRef={qualifRef}
        certRef={certRef}
        contactRef={contactRef}
      />
    </div>
  );
}

export default App;
