import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SectionTitle from '../sectionTitle';
import './certifications.scss';

const Certifications = (props, ref) => {
    return <Container ref={ref}>
        <SectionTitle title='Certifications' />
        <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
            <Col md={3}>
                <img src='/certs/az900.png' className='cert-image' alt='' />
            </Col>
        </Row>
    </Container>;
};

export default React.forwardRef(Certifications);