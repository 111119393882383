import {
  faBriefcase,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SectionTitle from "../sectionTitle";

const Timeline = (props, ref) => {
  const color = "#230e3a";
  const size = "lg";

  const items = [
    {
      id: 1,
      title: "2015 - 2018",
      cardTitle: "Bachelors Degree",
      cardSubtitle: "Bsc. (Hons) in Computer Science & Software Engineering",
      icon: (
        <FontAwesomeIcon icon={faGraduationCap} color={color} size={size} />
      ),
    },
    {
      id: 2,
      title: "2018 - 2021",
      cardTitle: "Profession - Cubo Systems",
      cardSubtitle: "Joined as a Trainee & advanced upto Software Engineer",
      icon: <FontAwesomeIcon icon={faBriefcase} color={color} size={size} />,
    },
    {
      id: 3,
      title: "2021 - 2022",
      cardTitle: "Profession - Iconblocks Inc.",
      cardSubtitle: "Working as a Senior Software Engineer",
      icon: <FontAwesomeIcon icon={faBriefcase} color={color} size={size} />,
    },
    {
      id: 4,
      title: "2022 - Present",
      cardTitle: "Profession - Vista Entertainment Solutions",
      cardSubtitle: "Working as a Software Engineer",
      icon: <FontAwesomeIcon icon={faBriefcase} color={color} size={size} />,
    },
  ];
  return (
    <Container ref={ref}>
      <SectionTitle title="Timeline" />
      <Row>
        <Col>
          <VerticalTimeline lineColor="#230e3a">
            {items.map((exp) => (
              <VerticalTimelineElement
                key={exp.id}
                position="right"
                contentStyle={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "#fbfbfe",
                }}
                className="vertical-timeline-element--work"
                date={exp.title}
                icon={exp.icon}
                iconStyle={{ background: "#e5d9f3", color: "#fff" }}
              >
                <h2>{exp.cardTitle}</h2>
                <div style={{ color: "grey" }}>{exp.cardSubtitle}</div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Col>
      </Row>
    </Container>
  );
};

export default React.forwardRef(Timeline);
