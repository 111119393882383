import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: "AIzaSyANqARwdE2un6kiMCFqu7_61scyQTcd8t4",
    authDomain: "portfolio-ee8cb.firebaseapp.com",
    projectId: "portfolio-ee8cb",
    storageBucket: "portfolio-ee8cb.appspot.com",
    messagingSenderId: "973995894460",
    appId: "1:973995894460:web:87026b7fc39a44a78f52b9",
    measurementId: "G-900QKFXCF8"
}

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Lf8Z7sdAAAAABXZZJNeP9OAM0TGwFc2uDDDtnWP'),
    isTokenAutoRefreshEnabled: true
})

export { app, analytics, db, appCheck };