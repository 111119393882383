import React from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';

const openLink = (link, source) => {
    logEvent(analytics, 'link_click', { 'source': source });
    window.open(link);
}

const ContactMethod = (props) => {

    return <div style={{ display: 'flex' }} onClick={() => openLink(props.url, props.name)}>
        <div className='contact-method-icon'>{props.icon}</div>
        <div>
            <div>{props.name}</div>
            <div className='contact-method-subtitle'>{props.subtitle}</div>
        </div>
    </div>;
};

export default ContactMethod;