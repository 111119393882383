import React, { useState } from 'react';
import { Container, Row, Modal } from 'react-bootstrap';
import SectionTitle from '../sectionTitle';
import ServiceCard from './serviceCard';
import './services.scss';

const Services = (props, ref) => {
    const [show, setShowModal] = useState(false);
    const [title, setTitle] = useState('');

    return <Container ref={ref}>
        <SectionTitle title='Services' />
        <Row style={{ justifyContent: 'center' }}>
            <ServiceCard title={'Mobile Development'} fileName='mobile' onTapped={(title) => {
                setShowModal(true);
                setTitle(title);
            }} />
            <ServiceCard title={'Web Development'} fileName='web' onTapped={(title) => {
                setShowModal(true);
                setTitle(title);
            }} />
            <ServiceCard title={'Backend Development'} fileName='backend' onTapped={(title) => {
                setShowModal(true);
                setTitle(title);
            }} />
        </Row>

        <Modal
            show={show}
            onHide={() => setShowModal(false)}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {title.toLowerCase() === 'mobile development' ? <ul className='service-list'>
                    <li>Flutter</li>

                </ul> : title.toLowerCase() === 'web development' ? <ul className='service-list'>

                    <li>React JS</li>
                    <li>TypeScript</li>
                    <li>Babel / Webpack</li>
                    <li>SCSS</li>

                </ul> : <ul className='service-list'>

                    <b>Frameworks</b>
                    <li>.NET Core / Framework</li>
                    <li>Spring Boot</li>
                    <li>Node JS with Express</li>
                    <b>Databases</b>
                    <li>MS SQL</li>
                    <li>MySQL</li>
                    <li>MongoDB</li>
                    <b>Web Servers</b>
                    <li>IIS</li>
                    <li>NginX</li>
                    <b>DevOps</b>
                    <li>Docker / Kubernetes</li>
                    <li>Microsoft Azure</li>
                    <li>Google Cloud Platform</li>
                </ul>}

            </Modal.Body>
            {/*  <Modal.Footer>
                <Button onClick={() => setShowModal(false)}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </Container>;
};

export default React.forwardRef(Services);