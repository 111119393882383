import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

const ContactField = (props) => {
    const [show, setShow] = useState(false);
    const errRef = useRef(null);

    const toggleTooltipShow = () => {
        setShow(!show);
    }

    return <div className='contact-field'>
        {props.hasError ? <div ref={errRef} className='contact-field-error-indicator' onClick={toggleTooltipShow} ><FontAwesomeIcon icon={faExclamationCircle} color='red' size='lg' /></div> : null}
        <div style={{ fontSize: '0.7rem' }}>{props.label}</div>
        {props.textarea ? <textarea className='contact-input' value={props.value} onChange={(evt) => props.onChange(evt.target.value)} label={props.label} /> : <input type='text' className='contact-input' value={props.value} onChange={(evt) => props.onChange(evt.target.value)} label={props.label} />}
        <Overlay target={errRef} show={show} placement='right'>
            {(props2) => (<Tooltip id="overlay-example" {...props2}>
                {props.validationMessage}
            </Tooltip>)}
        </Overlay>
    </div>;
}

export default ContactField;