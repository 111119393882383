import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import SectionTitle from '../sectionTitle';
import ContactField from './contactField';
import './contact.scss';
import ContactMethod from './contactMethod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../firebase';
import { collection, setDoc, doc } from 'firebase/firestore';




const Contact = (props, ref) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [messageValid, setMessageValid] = useState(true);

    const [nameValidationTxt, setNameValidationTxt] = useState('');
    const [emailValidationTxt, setEmailValidationTxt] = useState('');
    const [messageValidationTxt, setMessageValidationTxt] = useState('');

    const [isMessageSending, setIsMessageSending] = useState(false);
    const [isMessageSendSuccess, setIsMessageSendSuccess] = useState(null);

    const sendMessage = async () => {
        setIsMessageSendSuccess(null);
        const isValid = validate();

        if (!isValid) {
            return;
        }


        try {
            const inquiryRef = doc(collection(db, 'inquiries'));
            setIsMessageSending(true);
            await setDoc(inquiryRef, {
                'name': name,
                'email': email,
                'message': message,
                'dateTime': new Date()
            });

            setIsMessageSendSuccess(true);
            clearEverything();

        } catch (err) {
            setIsMessageSendSuccess(false);
        } finally {
            setIsMessageSending(false);
        }
    }

    const clearValidation = () => {
        setNameValid(true);
        setEmailValid(true);
        setMessageValid(true);

        setNameValidationTxt('');
        setEmailValidationTxt('');
        setMessageValidationTxt('');
    }

    const clearEverything = () => {
        clearValidation();
        setName('');
        setEmail('');
        setMessage('');
    }

    const validate = () => {
        clearValidation();
        let isValid = true;

        if (name.trim() === '') {
            setNameValid(false);
            setNameValidationTxt('Name is empty');
            isValid = false;
        }

        if (email.trim() === '') {
            setEmailValid(false);
            setEmailValidationTxt('Email is empty');
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailValid(false);
            setEmailValidationTxt('Email is not valid');
            isValid = false;
        }

        if (message.trim() === '') {
            setMessageValid(false);
            setMessageValidationTxt('Message is empty');
            isValid = false;
        }

        return isValid;
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email.toLowerCase().match(re);
    }


    return <Container ref={ref} className='contact-main-container'>
        <SectionTitle title='Contact' />
        <Container style={{ maxWidth: '768px', justifyContent: 'center' }}>
            <Row>
                <Col>
                    <ul className='contact-method-list'>
                        <li><ContactMethod name='LinkedIn' subtitle='Sajad Jaward' icon={<FontAwesomeIcon icon={faLinkedinIn} />} url='https://www.linkedin.com/in/sajadjaward' /></li>
                        <li><ContactMethod name='Github' subtitle='sajadj313' icon={<FontAwesomeIcon icon={faGithub} />} url='https://github.com/sajadj313' /></li>
                        <li><ContactMethod name='Stack Overflow' subtitle='Sajad Jaward' icon={<FontAwesomeIcon icon={faStackOverflow} />} url='https://stackoverflow.com/users/7958434/sajad-jaward?tab=profile' /></li>
                        <li><ContactMethod name='Email' subtitle='sajad.jaward1@gmail.com' icon={<FontAwesomeIcon icon={faEnvelope} />} url='mailto:sajad.jaward1@gmail.com' /></li>
                    </ul>
                </Col>
                <Col>
                    <ul className='contact-field-list'>
                        <li><ContactField label='Name' value={name} onChange={setName} hasError={!nameValid} validationMessage={nameValidationTxt} /></li>
                        <li><ContactField label='Email' value={email} onChange={setEmail} hasError={!emailValid} validationMessage={emailValidationTxt} /></li>
                        <li><ContactField label='Message' textarea value={message} onChange={setMessage} hasError={!messageValid} validationMessage={messageValidationTxt} /></li>
                        {isMessageSendSuccess != null && isMessageSendSuccess ? <li><div className='success-message'>Message has been submitted. Thank you for contacting.</div></li> : null}
                        {isMessageSendSuccess != null && !isMessageSendSuccess ? <li><div className='error-message'>Could not send your message. Please try again.</div></li> : null}
                        <li><Button disabled={isMessageSending} onClick={sendMessage}>{isMessageSending ? 'Sending...' : 'Send Message'}</Button></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </Container>;
};

export default React.forwardRef(Contact);